import LoginComponent from 'components/login/Login';
import { useQueryParams } from 'hooks/location';
import { CustomError } from 'components/error/Error';
import _ from 'ui/lodash';
import React from 'react';

function Login({ shop }) {

  const params = useQueryParams();
  const url = params.get('url');
  const type = params.get('type');

  const onToken = (token) => {

    window.location.href = url.replace('{TOKEN}', token);

  };

  if (!url || !url.includes('{TOKEN}')) {

    return (
      <CustomError code={404}/>
    );

  }
  return (
    <div>
      <LoginComponent type={type} shop={shop} onClose={onToken} getToken={true}/>
    </div>
  );

}

export const getServerSideProps = async (ctx) => {
  const { Becca } = require('server/api');
  const {
    shopId
  } = ctx.query;

  if (shopId) {
    try {
      const shop = await Becca.getSSRShopById(shopId);
      return {
        props: {
          shop: _.pick(shop, ['_id', 'slug', 'checkout.login'])
        }
      };
    } catch (e) {
      return {
        props: {}
      };
    }
  }

  return {
    props: {},
  };
};

export default Login;
